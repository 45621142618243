import React, { useEffect } from 'react';

import Main from '../components/Home/Main'
import WhyChoose from '../components/Home/WhyChoose'
import WhatDo from '../components/Home/WhatDo'
import WhatOffer from '../components/Home/WhatOffer'
import OurValues from '../components/Home/OurValues'
import YourSucess from '../components/Home/YourSucess'
import FeedBack from '../components/Home/FeedBack'

import Lazyload from 'react-lazy-load'

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Main/>
      <Lazyload><WhyChoose/></Lazyload>
      <Lazyload><WhatDo/></Lazyload>
      <Lazyload><WhatOffer/></Lazyload>
      <Lazyload><OurValues/></Lazyload>
      <Lazyload><YourSucess/></Lazyload>
      <Lazyload><FeedBack/></Lazyload>   
    </>
  );
}

export default Home;
