import React from 'react';

import Fade from 'react-reveal/Fade'

import img from '../../img/Services/img.jpg'

import { useTranslation } from "react-i18next";

function Global() {

  const { t } = useTranslation();

  return (
    <>
        <div className='relative mt-[120px] xl:mt-[180px] mx-[20px] max-w-[1280px] xl:mx-auto'>
            <div className='xl:absolute xl:bg-white bottom-0 left-0 xl:w-[805px] xl:h-[338px]'>
                <div className='bg-black h-[1px] w-[178px] xl:w-[280px] xl:mt-[80px]'></div>
                <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px]'>· {t('services.38')}</h1>
                <p className='xl:max-w-[600px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2] mt-[20px] xl:mt-[40px]'>{t('services.39')}</p>
                <p className='xl:max-w-[600px] inter text-[14px] xl:text-[16px] font-[300] text-[#000000B2] mt-[20px]'>{t('services.40')}</p>
            </div>
            <img src={img} alt="" className='mt-[20px] xl:mt-0'/>
        </div>
    </>
  );
}

export default Global;
