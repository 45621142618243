import React from 'react';

import what1 from '../../img/whatdo1.svg'
import what2 from '../../img/whatdo2.svg'
import what3 from '../../img/whatdo3.svg'

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

function WhatDo() {

  const { t } = useTranslation();

  return (
    <>
        <div className='mt-[120px] xl:mt-[180px] max-w-[1280px] mx-auto'>
            <div className='flex flex-col xl:flex-row'>
                <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px] ml-[20px] xl:ml-0'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] ml-[20px] xl:ml-0'>· {t('home.15')}</h1>
                </div>
                <div className='xl:ml-[70px] xl:w-full mt-[40px] xl:mt-0 xl:mr-0 ml-[20px] mr-[20px]'>
                    <Fade bottom><div className='flex flex-col xl:flex-row'>
                        <div>
                            <img src={what1} className="w-[82px] h-[60px] xl:w-[110px] xl:h-[80px]" alt="" />
                        </div>
                        <div className='xl:ml-[60px] mt-[20px] xl:mt-0'>
                            <h1 className='inter text-[16px] xl:text-[20px]'>{t('home.16')}</h1>
                            <p className='textcolor inter text-[12px] xl:text-[16px] font-light mt-[24px] max-w-[700px]'>{t('home.17')}</p>
                        </div>
                    </div></Fade>
                    <div className='w-full colorline h-[1px] mt-[40px]'></div>
                    <Fade bottom delay={300}><div className='flex flex-col xl:flex-row mt-[40px]'>
                        <div>
                            <img src={what2} className="w-[82px] h-[60px] xl:w-[110px] xl:h-[80px]" alt="" />
                        </div>
                        <div className='xl:ml-[60px] mt-[20px] xl:mt-0'>
                            <h1 className='inter text-[16px] xl:text-[20px]'>{t('home.18')}</h1>
                            <p className='textcolor inter text-[12px] xl:text-[16px] font-light mt-[24px] max-w-[700px]'>{t('home.19')}</p>
                        </div>
                    </div></Fade>
                    <div className='w-full colorline h-[1px] mt-[40px]'></div>
                    <Fade bottom delay={600}><div className='flex flex-col xl:flex-row mt-[40px]'>
                        <div>
                            <img src={what3} className="w-[82px] h-[60px] xl:w-[110px] xl:h-[80px]" alt="" />
                        </div>
                        <div className='xl:ml-[60px] mt-[20px] xl:mt-0'>
                            <h1 className='inter text-[16px] xl:text-[20px]'>{t('home.20')}</h1>
                            <p className='textcolor inter text-[12px] xl:text-[16px] font-light mt-[24px] max-w-[700px]'>{t('home.21')}</p>
                        </div>
                    </div></Fade>
                </div>
            </div>
        </div>
    </>
  );
}

export default WhatDo;
