import React from 'react';

import what1 from '../../img/Services/s1.svg'
import what2 from '../../img/Services/s2.svg'
import what3 from '../../img/Services/s3.svg'

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

function WhyPartner() {

  const { t } = useTranslation();

  return (
    <>
        <div className='mt-[120px] xl:mt-[180px] max-w-[1280px] mx-auto'>
            <div className='flex flex-col xl:flex-row'>
                <div>
                    <div className='bg-black h-[1px] w-[178px] xl:w-[280px] ml-[20px] xl:ml-0'></div>
                    <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] ml-[20px] xl:ml-0 xl:max-w-[250px]'>· {t('services.9')}</h1>
                </div>
                <div className='xl:ml-[70px] xl:w-full mt-[40px] xl:mt-0 xl:mr-0 ml-[20px] mr-[20px]'>
                    <Fade bottom><div className='flex flex-col xl:flex-row'>
                        <div>
                            <img src={what1} className="w-[82px] h-[60px] xl:w-[110px] xl:h-[80px]" alt="" />
                        </div>
                        <div className='xl:ml-[60px] mt-[20px] xl:mt-0'>
                            <h1 className='inter text-[16px] xl:text-[20px]'>{t('services.10')}</h1>
                            <p className='textcolor inter text-[12px] xl:text-[16px] font-light mt-[24px] max-w-[700px]'>{t('services.11')}</p>
                        </div>
                    </div></Fade>
                    <div className='w-full colorline h-[1px] mt-[40px]'></div>
                    <Fade bottom delay={300}><div className='flex flex-col xl:flex-row mt-[40px]'>
                        <div>
                            <img src={what2} className="w-[82px] h-[60px] xl:w-[110px] xl:h-[80px]" alt="" />
                        </div>
                        <div className='xl:ml-[60px] mt-[20px] xl:mt-0'>
                            <h1 className='inter text-[16px] xl:text-[20px]'>{t('services.12')}</h1>
                            <p className='textcolor inter text-[12px] xl:text-[16px] font-light mt-[24px] max-w-[700px]'>{t('services.13')}</p>
                        </div>
                    </div></Fade>
                    <div className='w-full colorline h-[1px] mt-[40px]'></div>
                    <Fade bottom delay={600}><div className='flex flex-col xl:flex-row mt-[40px]'>
                        <div>
                            <img src={what3} className="w-[82px] h-[60px] xl:w-[110px] xl:h-[80px]" alt="" />
                        </div>
                        <div className='xl:ml-[60px] mt-[20px] xl:mt-0'>
                            <h1 className='inter text-[16px] xl:text-[20px]'>{t('services.14')}</h1>
                            <p className='textcolor inter text-[12px] xl:text-[16px] font-light mt-[24px] max-w-[700px]'>{t('services.15')}</p>
                        </div>
                    </div></Fade>
                </div>
            </div>
        </div>
    </>
  );
}

export default WhyPartner;
