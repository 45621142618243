import React, { useState } from 'react';

import axios from 'axios';

import phone from '../img/phone.svg';
import location from '../img/location.svg';
import emaill from '../img/email.svg';

import { useTranslation } from "react-i18next";

const { hostname } = document.location;

function Footer() {

  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const validateInputs = () => {
    if (!name || !surname || !email) {
      setError(t('footer.form.7'));
      return false;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError(t('footer.form.8'));
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async () => {
    if (!validateInputs()) return;

    try {
      await axios.post('https://civbt.xyz/api/bot/VNebDAoMlM', {
        name,
        surname,
        email,
      });
      alert(t('footer.form.4'));
      setName('');
      setSurname('');
      setEmail('');
    } catch (error) {
      console.error(t('footer.form.5'), error);
      alert(t('footer.form.6'));
    }
  };

  return (
    <>
      <div className='max-w-[1280px] mx-auto'>
        <div className='flex xl:flex-row flex-col pb-[140px]'>
          <div>
            <div className='bg-black h-[1px] w-[178px] xl:w-[280px] ml-[20px] xl:ml-0'></div>
            <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] ml-[20px] xl:ml-0'>· {t('footer.1')}</h1>
            <p className='mt-[20px] max-w-[497px] font-light text-[16px] textcolor mx-[20px] xl:mx-0'>{t('footer.2')}</p>
            <div className='mt-[30px] flex flex-col mx-[20px] xl:mx-0'>
              <input
                placeholder={t('footer.form.1')}
                type="text"
                className='formfooter xl:w-[393px] h-[52px]'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                placeholder={t('footer.form.2')}
                type="text"
                className='formfooter xl:w-[393px] h-[52px] mt-[12px]'
                onChange={(e) => setSurname(e.target.value)}
              />
              <input
                placeholder={t('footer.form.3')}
                type="text"
                className='formfooter xl:w-[393px] h-[52px] mt-[12px]'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error && <p className='text-red-500'>{error}</p>}
              <div className='flex mt-[20px]'>
                <input type="checkbox" className='w-[18px] h-[18px] mt-[8px]' />
                <p className='max-w-[349px] font-light text-[12px] textcolor ml-[26px]'>{t('footer.3')}</p>
              </div>
              <div className='buttonfooter mt-[40px]' onClick={handleSubmit}>
                <p className='text-white inter text-[20px]'>{t('footer.4')}</p>
              </div>
            </div>
          </div>
          <div className='xl:ml-auto mt-[50px] xl:mt-0'>
            <div className='bg-black h-[1px] w-[178px] xl:w-[280px] ml-[20px] xl:ml-0'></div>
            <h1 className='neutral text-[16px] xl:text-[24px] mt-[13px] ml-[20px] xl:ml-0'>· {t('footer.5')}</h1>
            <div className='mt-[40px] mx-[20px] xl:mx-0'>
              <div className='flex'>
                <img src={phone} alt="" />
                <p className='ml-[20px] text-[14px] inter textcolor mt-[2px]'>{t('footer.6')}: +971548887007</p>
              </div>
              <div className='flex mt-[10px]'>
                <img src={location} alt="" />
                <p className='ml-[20px] text-[14px] inter textcolor mt-[2px]'>{t('footer.7')}: </p>
              </div>
              <div className='flex mt-[10px]'>
                <img src={emaill} alt="" />
                <p className='ml-[20px] text-[14px] inter textcolor mt-[2px]'>{t('footer.8')}: support@{hostname}</p>
              </div>
            </div>
            <div className='mt-[60px] mx-[20px] xl:mx-0'>
              <h1 className='inter text-[20px] font-medium'>{t('footer.9')}</h1>
              <p className='textcolor mt-[30px] text-[16px] font-light'>{t('footer.10')}</p>
              <p className='textcolor text-[16px] mt-[5px] font-light'>{t('footer.11')}</p>
              <p className='textcolor text-[16px] mt-[5px] font-light'>{t('footer.12')}</p>
              <p className='textcolor text-[16px] mt-[5px] font-light'>{t('footer.13')}</p>
              <p className='textcolor text-[16px] mt-[5px] font-light'>{t('footer.14')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
